<template>
  <div class="mod-companysubsidydetail">
    <el-form
      :inline="true"
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
    >
      <el-form-item>
        <el-input
          v-model="searchForm.name"
          placeholder="公司名"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="searchForm.type" placeholder="补助类型" clearable>
          <el-option key="1" label="按天补助" value="1" />
          <el-option key="2" label="按月补助" value="2" />
          <el-option key="3" label="按次补助" value="3" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="searchForm.arriveStatus"
          placeholder="是否发放成功"
          clearable
        >
          <el-option key="0" label="发放成功" value="0" />
          <el-option key="1" label="余额不足" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList(1)">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司名"
      >
      </el-table-column>
      <el-table-column
        prop="subsidyMoney"
        header-align="center"
        align="center"
        label="发放补助额度"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        header-align="center"
        align="center"
        label="发放时间"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="发放补助类型"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 1" size="mini">按天补助</el-tag>
          <el-tag type="success" v-else-if="scope.row.type === 2" size="mini"
            >按月补助</el-tag
          >
          <el-tag type="warning" v-else-if="scope.row.type === 3" size="mini"
            >按次补助</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="arriveStatus"
        header-align="center"
        align="center"
        label="发放状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.arriveStatus === 0" size="mini"
            >发放成功</el-tag
          >
          <el-tag
            type="info"
            v-else-if="scope.row.arriveStatus === 1"
            size="mini"
            >余额不足</el-tag
          >
          <el-tag type="success" v-if="scope.row.arriveStatus === 3" size="mini"
            >退还成功</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="remarks"
        header-align="center"
        align="center"
        label="备注"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        name: '',
        arriveStatus: '',
        type: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
    };
  },
  activated() {
    this.getDataList(1);
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$http({
        url: '/tc/companysubsidydetail/list',
        method: 'get',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          name: this.searchForm.name,
          arriveStatus: this.searchForm.arriveStatus,
          type: this.searchForm.type,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
  },
};
</script>
